import { Button, Grid, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import { useEffect, useState } from 'react';

import { useCommonStyles } from '../../root/theme';
import { getElementPositionFromTop } from '../../utils/getElementPositionFromTop';
import { preloadImage } from '../../utils/preloadImage';
import { getPortalSignUpUrl } from '../../utils/urls';
import { SlantAngle, SlantContainer, SlantGradient } from '../slant';

export const mobileBreakpoint = 450;

const useStyles = makeStyles((theme: Theme) => {
  return {
    sectionHeight: {
      paddingTop: 120,
      paddingBottom: 120,
      [theme.breakpoints.down('md')]: {
        paddingTop: 40,
        paddingBottom: 40,
      },
    },
    alignText: {
      textAlign: 'left',
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
        maxWidth: '630px',
      },
    },
    headerSmall: {
      fontSize: '50px',
      lineHeight: '55px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '44px',
        lineHeight: '48px',
      },
    },
    headerLarge: {
      fontSize: '70px',
      lineHeight: '70px',
      marginBottom: '20px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '60px',
        lineHeight: '60px',
      },
    },
    body: {
      fontSize: '24px',
      marginBottom: 8,
      [theme.breakpoints.down('xs')]: {
        fontSize: '20px',
      },
    },
    button: {
      backgroundColor: '#FDC72A',
      borderRadius: 8,
      boxShadow: '0 4px 4px 0 rgba(0,0,0,0.50)',
      marginTop: 30,
      textTransform: 'none',
    },
    laptop: {
      width: '100%',
      height: 'auto',
      maxHeight: '400px',
      maxWidth: '660px',
      [theme.breakpoints.down('md')]: {
        marginBottom: 50,
      },
      [theme.breakpoints.down(mobileBreakpoint)]: {
        width: '115%',
        transform: 'translateX(-20px)', // re-center larger laptop
      },
    },
  };
});

const JoinTheRevolutionSection: React.FC = () => {
  const classes = useStyles({});
  const commonClasses = useCommonStyles();
  const [allLaptopImagesAreLoaded, setAllLaptopImagesAreLoaded] = useState(
    false
  );

  // Animation variables that don't rely on global objects
  const frameCount = 49; // (0-48) Change if number of frames in animation is changed
  const pixelsPerFrame = 7.5; // Determines speed of animation. Fewer frames is faster, more is slower
  const animationDurationInPixels = frameCount * pixelsPerFrame;

  const getCurrentAnimationFrame = index => {
    return `https://s3.amazonaws.com/assets.findingfocus.app/production/imagery/laptopPNGsequence/Finding+Foucus_Reveal+MacBook+Pro_UPDATE_3.14.23_${index
      .toString()
      .padStart(4, '0')}.png`;
  };

  // Initialize laptop frame index at 0
  const [currentAnimationFrameIndex, setCurrentAnimationFrameIndex] = useState(
    48
  );
  // As user scrolls, calculate and set currentAnimationFrameIndex
  const handleScroll = () => {
    // 1. calculate position at which animation should start, depending on window height.
    //    the lower the percentage, the higher the animation will start in the window
    const laptopPositionAtAnimationStart = window.innerHeight * 0.8;

    // 2. calculate position at which animation should end
    const laptopPositionAtAnimationEnd =
      laptopPositionAtAnimationStart - animationDurationInPixels;

    // 3. calculate currentAnimationFrameIndex from current laptop position
    //    If laptop is above the start position, display first frame.
    //    If laptop is below the end position, display last frame.
    //    If laptop is between the start and end position, calculate current frame by
    //    dividing laptop's position within the range by number of pixels per frame.
    const laptopElement = document.getElementById('laptop');
    const laptopPositionFromTop = Math.floor(
      getElementPositionFromTop(laptopElement)
    );
    setCurrentAnimationFrameIndex(
      laptopPositionFromTop >= laptopPositionAtAnimationStart
        ? 0
        : laptopPositionFromTop <= laptopPositionAtAnimationEnd
        ? 48
        : Math.floor(
            (laptopPositionAtAnimationStart - laptopPositionFromTop) /
              pixelsPerFrame
          )
    );
  };

  const preloadImages = async () => {
    const promises = Array.from({ length: frameCount }, (_, i) => {
      return preloadImage(getCurrentAnimationFrame(i));
    });

    try {
      await Promise.all(promises);
      setAllLaptopImagesAreLoaded(true); // All images for laptop animation sequence are loaded
    } catch (error) {
      console.error('Some images failed to load', error);
    }
  };

  useEffect(() => {
    if (!allLaptopImagesAreLoaded) {
      // if all images are not yet loaded, preload images
      preloadImages();
    } else if (allLaptopImagesAreLoaded) {
      // if all images have been loaded, activate scroll animation
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [allLaptopImagesAreLoaded]);

  return (
    <SlantContainer
      angle={SlantAngle.RIGHT_TO_LEFT}
      gradient={SlantGradient.HORIZONTAL}
      onlyTop={true}
      centerContents={true}
    >
      <Grid
        container
        className={clsx(
          classes.sectionHeight,
          classes.alignText,
          commonClasses.flexCenterContents
        )}
      >
        <Grid item md={12} lg={7}>
          <img
            id={'laptop'}
            src={getCurrentAnimationFrame(currentAnimationFrameIndex)}
            className={classes.laptop}
            alt="a laptop displaying the dashboard of the Finding Focus web app"
          />
        </Grid>
        <Grid item md={12} lg={5}>
          <Typography
            variant="h4"
            color="textSecondary"
            className={classes.headerSmall}
          >
            Join the
          </Typography>
          <Typography
            variant="h1"
            color="textSecondary"
            className={classes.headerLarge}
          >
            attention revolution
          </Typography>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            className={classes.body}
          >
            In the era of unlimited distraction, focus can be your greatest
            asset. Our course is free to everyone so that you can learn how to
            reclaim your focus and take charge of how you feel.
          </Typography>
          <Button
            size="large"
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={e => {
              e.preventDefault();
              window.location.href = getPortalSignUpUrl();
            }}
          >
            Get started
          </Button>
        </Grid>
      </Grid>
    </SlantContainer>
  );
};

export default JoinTheRevolutionSection;
