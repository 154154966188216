import { Divider, Grid, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';

import { MatrixBackground } from '../../assets';
import { useCommonStyles } from '../../root/theme';

const useStyles = makeStyles((theme: Theme) => {
  return {
    sectionHeight: {
      paddingTop: 140,
      paddingBottom: 140,
      [theme.breakpoints.down('sm')]: {
        paddingTop: 100,
        paddingBottom: 100,
      },
    },
    matrixBackground: {
      backgroundImage: `url(${MatrixBackground})`,
      backgroundAttachment: 'scroll',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundColor: '#FFF',
    },
    cardBig: {
      boxShadow: '3px 3px 11px 7px rgba(98,97,97,0.50)',
      borderRadius: 15,
      backgroundColor: '#fff',
      maxWidth: 824,
      width: '90%',
      padding: 40,
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        maxWidth: 724,
      },
    },
    header: {
      [theme.breakpoints.down('xs')]: {
        fontSize: '28px',
        letterSpacing: '.65px',
        lineHeight: '32px',
      },
    },
    subheader: {
      marginBottom: 10,
      [theme.breakpoints.down('xs')]: {
        fontSize: '20px',
        letterSpacing: 1,
        lineHeight: '28px',
      },
    },
  };
});

const MatrixSection: React.FC = () => {
  const classes = useStyles({});
  const commonClasses = useCommonStyles({});

  return (
    <Grid
      container
      className={clsx(
        classes.sectionHeight,
        classes.matrixBackground,
        commonClasses.flexCenterContents
      )}
    >
      <Grid item className={classes.cardBig}>
        <Typography variant="h3" color="textPrimary" className={classes.header}>
          Your brain is constantly bombarded with information from both inside
          and out
        </Typography>
        <Divider className={commonClasses.divider} variant="middle" />
        <Typography
          variant="subtitle1"
          color="textPrimary"
          className={classes.subheader}
        >
          When attention works well, it filters out unhelpful thoughts and
          distractions so you can focus on what matters most.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default MatrixSection;
