import { Box, Grid, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { RightArrow } from '../../assets';
import attentionLottie from '../../lottie/attention.json';
import emotionsLottie from '../../lottie/emotions.json';
import thoughtsLottie from '../../lottie/thoughts.json';
import { useCommonStyles } from '../../root/theme';
import LottieWithSubtitle from '../lottieWithSubtitle';
import { SlantAngle, SlantContainer, SlantGradient } from '../slant';

const useStyles = makeStyles((theme: Theme) => {
  return {
    sectionHeight: {
      paddingTop: 60,
      paddingBottom: 80,
      [theme.breakpoints.down('xs')]: {
        paddingTop: 30,
        paddingBottom: 40,
      },
    },
    flexDirection: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    lottieGroupContainer: {
      width: '100%',
      maxWidth: '700px',
      marginTop: 50,
      marginBottom: 50,
      [theme.breakpoints.down('xs')]: {
        height: '800px',
        marginTop: 20,
        marginBottom: 20,
      },
    },
    headerText: {
      maxWidth: '600px',
      textAlign: 'center',
      [theme.breakpoints.down(800)]: {
        fontSize: 32,
      },
    },
    subheaderText: {
      maxWidth: '700px',
      [theme.breakpoints.down(800)]: {
        fontSize: 20,
      },
    },
    // animation styles
    hiddenWithSlide: {
      opacity: 0,
      filter: 'blur(5px)',
      transform: 'translateY(50%)',
    },
    hiddenWithoutSlide: {
      opacity: 0,
      filter: 'blur(5px)',
    },
    show: {
      opacity: 1,
      filter: 'blur(0)',
      transition: 'all 0.5s',
    },
    // transition delays and arrow irregularities
    header: {
      transitionDelay: '0ms',
    },
    attentionLottie: {
      transitionDelay: '500ms',
    },
    arrowOne: {
      transition: 'opacity 0.5s, filter 0.3s',
      transitionDelay: '1000ms',
      [theme.breakpoints.down('xs')]: {
        transform: 'rotate(90deg) translate(0,40%)',
      },
    },
    thoughtsLottie: {
      transitionDelay: '1250ms',
    },
    arrowTwo: {
      transition: 'opacity 0.5s, filter 0.3s',
      transitionDelay: '1750ms',
      [theme.breakpoints.down('xs')]: {
        transform: 'rotate(90deg) translate(0,40%)',
      },
    },
    emotionsLottie: {
      transitionDelay: '2000ms',
    },
    subheader: {
      transitionDelay: '2500ms',
      textAlign: 'center',
    },
  };
});

const WhereYouFocusSection: React.FC = () => {
  const classes = useStyles({});
  const commonClasses = useCommonStyles({});

  // Use intersection observer to trigger animation and set content to show
  const { ref: lottieRowRef, inView: lottieRowIsVisible } = useInView();
  const [showContent, setShowContent] = useState(false);
  if (lottieRowIsVisible && !showContent) {
    setShowContent(true);
  }

  return (
    <SlantContainer
      angle={SlantAngle.RIGHT_TO_LEFT}
      gradient={SlantGradient.HORIZONTAL}
      onlyBottom={true}
      centerContents={true}
    >
      <Grid
        className={clsx(
          classes.sectionHeight,
          commonClasses.flexColumn,
          commonClasses.flexCenterContents
        )}
      >
        <Grid
          item
          className={
            showContent
              ? clsx(classes.show, classes.header)
              : classes.hiddenWithoutSlide
          }
        >
          <Typography
            variant="h2"
            color="textSecondary"
            className={classes.headerText}
          >
            WHERE YOU FOCUS AFFECTS WHAT YOU FEEL
          </Typography>
        </Grid>
        <Grid
          ref={lottieRowRef}
          className={clsx(classes.flexDirection, classes.lottieGroupContainer)}
        >
          <Grid
            item
            className={
              showContent
                ? clsx(classes.show, classes.attentionLottie)
                : classes.hiddenWithSlide
            }
          >
            <LottieWithSubtitle
              subtitle={'ATTENTION'}
              lottieAnimationData={attentionLottie}
              isVisible={lottieRowIsVisible}
            />
          </Grid>
          <Grid
            item
            className={
              showContent
                ? clsx(classes.show, classes.arrowOne)
                : classes.hiddenWithoutSlide
            }
          >
            <RightArrow />
          </Grid>
          <Grid
            item
            className={
              showContent
                ? clsx(classes.show, classes.thoughtsLottie)
                : classes.hiddenWithSlide
            }
          >
            <LottieWithSubtitle
              subtitle={'THOUGHTS'}
              lottieAnimationData={thoughtsLottie}
              isVisible={lottieRowIsVisible}
              speed={0.8}
            />
          </Grid>
          <Grid
            item
            className={
              showContent
                ? clsx(classes.show, classes.arrowTwo)
                : classes.hiddenWithoutSlide
            }
          >
            <RightArrow />
          </Grid>
          <Grid
            item
            className={
              showContent
                ? clsx(classes.show, classes.emotionsLottie)
                : classes.hiddenWithSlide
            }
          >
            <LottieWithSubtitle
              subtitle={'EMOTIONS'}
              lottieAnimationData={emotionsLottie}
              isVisible={lottieRowIsVisible}
            />
          </Grid>
        </Grid>
        <Box
          id={'subheader'}
          className={
            showContent
              ? clsx(classes.show, classes.subheader)
              : classes.hiddenWithoutSlide
          }
        >
          <Typography
            variant="subtitle1"
            color="textSecondary"
            className={classes.subheaderText}
          >
            Attention determines which thoughts you elaborate and which you let
            go. In turn your thoughts influence which emotions you feel.
          </Typography>
        </Box>
      </Grid>
    </SlantContainer>
  );
};

export default WhereYouFocusSection;
