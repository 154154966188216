import { Theme, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import Lottie from 'react-lottie';

import theme from '../root/theme';

const useStyles = makeStyles((theme: Theme) => {
  return {
    lottieSubtitle: {
      fontSize: '22px',
      marginTop: 20,
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        fontSize: '18px',
        marginTop: 20,
      },
    },
  };
});

interface Props {
  subtitle: string;
  // eslint-disable-next-line
  lottieAnimationData: any;
  isVisible: boolean;
  speed?: number;
}

const LottieWithSubtitle: React.FC<Props> = ({
  subtitle,
  lottieAnimationData,
  isVisible,
  speed = 1,
}: Props) => {
  const classes = useStyles({});
  const isScreenXSmall = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: lottieAnimationData,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        width={isScreenXSmall ? 110 : 130}
        height={isScreenXSmall ? 110 : 130}
        isPaused={!isVisible}
        isClickToPauseDisabled={true}
        speed={speed}
      />
      <Typography
        variant="h5"
        color="textSecondary"
        className={classes.lottieSubtitle}
      >
        {subtitle}
      </Typography>
    </>
  );
};

export default LottieWithSubtitle;
