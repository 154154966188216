import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import JoinTheRevolutionSection from '../components/whyItWorks/joinTheRevolutionSection';
import MatrixSection from '../components/whyItWorks/matrixSection';
import ProvenSolutionSection from '../components/whyItWorks/provenSolutionSection';
import WhereYouFocusSection from '../components/whyItWorks/whereYouFocusSection';
import WhyItWorksHeader from '../components/whyItWorks/whyItWorksHeader';

const IndexPage: React.FC = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <WhyItWorksHeader />
      <MatrixSection />
      <WhereYouFocusSection />
      <ProvenSolutionSection />
      <JoinTheRevolutionSection />
    </Layout>
  );
};

export default IndexPage;
