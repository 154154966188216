import { Container, Divider, Grid, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';

import { ScientificPapers } from '../../assets';
import { useCommonStyles } from '../../root/theme';
import { SlantAngle, SlantContainer, SlantGradient } from '../slant';

const useStyles = makeStyles((theme: Theme) => {
  return {
    sectionHeight: {
      paddingTop: 120,
      paddingBottom: 120,
      paddingLeft: 0,
      paddingRight: 0,
      [theme.breakpoints.down('sm')]: {
        paddingTop: 60,
        paddingBottom: 60,
      },
    },
    flexDirection: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
      },
    },
    scientificPapers: {
      maxWidth: '300px',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '250px',
      },
    },
    typographyContainer: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '650px',
      paddingLeft: 30,
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        paddingBottom: 50,
        paddingLeft: 0,
      },
    },
    header: {
      [theme.breakpoints.down('xs')]: {
        fontSize: 32,
      },
    },
    body: {
      [theme.breakpoints.down('xs')]: {
        fontSize: 20,
      },
    },
  };
});

const ProvenSolutionSection: React.FC = () => {
  const classes = useStyles({});
  const commonClasses = useCommonStyles({});

  return (
    <SlantContainer
      angle={SlantAngle.LEFT_TO_RIGHT}
      gradient={SlantGradient.WHITE}
      centerContents={true}
    >
      <Container className={classes.sectionHeight}>
        <Grid className={classes.flexDirection}>
          <Grid
            item
            xs={12}
            md={5}
            className={commonClasses.flexCenterContents}
          >
            <img
              src={ScientificPapers}
              alt="A stack of scientific research papers"
              className={classes.scientificPapers}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            className={clsx(
              commonClasses.flexCenterContents,
              classes.typographyContainer
            )}
          >
            <Typography
              variant="h2"
              color="textPrimary"
              className={classes.header}
            >
              Training attention is a proven solution
            </Typography>
            <Divider
              className={clsx(commonClasses.divider, commonClasses.dividerLeft)}
              variant="middle"
            />
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className={classes.body}
            >
              Three scientific studies conducted by the University of California
              have shown that Finding Focus improves high school students’
              classroom focus, stress management, and emotional regulation.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </SlantContainer>
  );
};

export default ProvenSolutionSection;
