import { Grid, Theme, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import Lottie from 'react-lottie';

import lottieActiveNodes from '../../lottie/active-nodes-edges.json';
import theme, { useCommonStyles } from '../../root/theme';

const useStyles = makeStyles((theme: Theme) => {
  return {
    sectionHeight: {
      paddingTop: 200,
      paddingBottom: 180,
    },
    heroLottie: {
      position: 'absolute',
      zIndex: -1,
      top: 0,
      left: 0,
      maxWidth: '100%',
      overflowX: 'hidden',
      [theme.breakpoints.down('xs')]: {
        transform: 'translateY(-50px)',
      },
    },
    headerContainer: {
      textAlign: 'center',
      paddingLeft: 20,
      paddingRight: 20,
    },
    headerLarge: {
      fontFamily: 'Bebas Neue, Arial, sans-serif',
      fontSize: '84px',
      letterSpacing: '2px',
      lineHeight: '84px',
      fontWeight: 700,
      [theme.breakpoints.down('xs')]: {
        fontSize: '64px',
        letterSpacing: '1.4px',
        lineHeight: '64px',
      },
    },
    headerSmall: {
      fontFamily: 'Bebas Neue, Arial, sans-serif',
      fontSize: '75px',
      letterSpacing: '1.78px',
      lineHeight: '75px',
      fontWeight: 400,
      marginBottom: 15,
      [theme.breakpoints.down('xs')]: {
        fontSize: '48px',
        letterSpacing: '1.33px',
        lineHeight: '48px',
        marginBottom: 10,
      },
    },
    subheaderContainer: {
      textAlign: 'center',
      maxWidth: '500px',
      paddingLeft: 15,
      paddingRight: 15,
      [theme.breakpoints.down('xs')]: {
        maxWidth: '400px',
      },
    },
    subheader: {
      [theme.breakpoints.down('xs')]: {
        fontSize: '20px',
        letterSpacing: 1,
        lineHeight: '28px',
      },
    },
  };
});

const WhyItWorksHeader: React.FC = () => {
  const classes = useStyles({});
  const commonClasses = useCommonStyles({});
  const isExtraLarge = useMediaQuery(theme.breakpoints.up('xl'));

  return (
    <Grid
      className={clsx(
        classes.sectionHeight,
        commonClasses.flexCenterContents,
        commonClasses.flexColumn
      )}
    >
      <div className={classes.heroLottie}>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: lottieActiveNodes,
            rendererSettings: {
              className: classes.heroLottie,
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
          height={'1080px'}
          width={isExtraLarge ? '100vw' : '1920px'}
          isClickToPauseDisabled={true}
          speed={0.8}
        />
      </div>
      <Grid
        className={clsx(
          commonClasses.flexCenterContents,
          commonClasses.flexColumn
        )}
      >
        <Grid item className={classes.headerContainer}>
          <Typography color="textSecondary" className={classes.headerLarge}>
            ATTENTION TRAINING
          </Typography>
          <Typography color="textSecondary" className={classes.headerSmall}>
            BACKED BY SCIENCE
          </Typography>
        </Grid>
        <Grid item className={classes.subheaderContainer}>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            className={classes.subheader}
          >
            Discover our evidence-based approach to improving both focus and
            well-being
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WhyItWorksHeader;
